<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #f7f5f9">
		<BHLoading :show="loading" />
		<FilterDrawer />
		<div class="dF aC jSB">
			<h4 class="mb-3 mt-3"> Archived Worksheets</h4>
			<div @click="showFilter" class="dF aC px-3 py-2 ml-3"
				style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
				<div class="mr-2">Filters</div>
				<i class="fe fe-filter" />
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }">
			<div>
				<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="worksheets"
					:loading="loading"
					:pagination="{
                        current: currentPage,
                        total: totalWorksheets,
                        pageSize: pageSize,
                    }"
					@change="handleChange"
					class="white-table hide-scrollbar" :scroll="{ x: 1350 }">
					<div slot="readableId" slot-scope="worksheet">
						{{ worksheet.readableId }}
					</div>
					<div slot="lot1" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[0] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[0] &&
								getLotName(worksheet.data.lots[0], worksheet.data.units[0])
							}}
						</span>

					</div>
					<div slot="lot2" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[1] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[1] &&
								getLotName(worksheet.data.lots[1], worksheet.data.units[1])
							}}
						</span>
					</div>
					<div slot="lot3" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[2] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[2] &&
								getLotName(worksheet.data.lots[2], worksheet.data.units[2])
							}}
						</span>
					</div>
					<div slot="unit1" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[0] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[0] &&
								getUnitNumber(worksheet.data.units[0])
							}}
						</span>

					</div>
					<div slot="unit2" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[1] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[1] &&
								getUnitNumber(worksheet.data.units[1])
							}}
						</span>
					</div>
					<div slot="unit3" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[2] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[2] &&
								getUnitNumber(worksheet.data.units[2])
							}}
						</span>
					</div>
					<div slot="parking" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.parking && worksheet.data.addons.parking.length">{{
							getAddOnNames(worksheet.data.addons.parking, 'parking') }}</span>
					</div>
					<div slot="lockers" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.lockers && worksheet.data.addons.lockers.length">{{
							getAddOnNames(worksheet.data.addons.lockers, 'lockers') }}</span>
					</div>
					<div slot="bikeRacks" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.bikeRacks && worksheet.data.addons.bikeRacks.length">{{
							getAddOnNames(worksheet.data.addons.bikeRacks, 'bikeRacks') }}</span>
					</div>
					<div slot="purchasers" slot-scope="worksheet">
						{{ getPurchaserNames(worksheet) }}
					</div>
					<div slot="submittedBy" slot-scope="worksheet">
						{{ getAssigneeName(worksheet) }}
					</div>
					<div slot="status" slot-scope="worksheet" class="text-center">
						<span class="badge-offer tT" :class="classLookup(worksheet.status).color">
							{{ classLookup(worksheet.status).text }}
						</span>
					</div>
					<div slot="declineReason" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.status === 'declined'">{{ worksheet.declineReason }}</span>
					</div>
					<div slot="createdAt" slot-scope="worksheet">
						{{ getDate(worksheet.createdAt) }}
					</div>
					<div slot="updatedAt" slot-scope="worksheet">
						{{ getDate(worksheet.updatedAt) }}
					</div>

					<div slot="actions" slot-scope="worksheet" class="dF jSA">
						<a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
							<div slot="content">
								<div class="popoverContent dF aC" @click="editWorksheet(worksheet)">
									<span v-if="isAgent">
										{{ ['pending', 'declined'].includes(worksheet.status) ? "Edit" : "View" }}
									</span>
									<span v-else>
										View
									</span>
								</div>
								<div v-if="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.reservation"
									class="popoverContent dF aC" @click="deleteWorksheet(worksheet)">
									Delete
								</div>
							</div>
							<div class="more-option-icon pr-4">
								<a-icon style="line-height: 40px" type="more" />
							</div>
						</a-popover>
					</div>
				</a-table>
			</div>
		</a-card>
	</div>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";
import moment from "moment";
import FilterDrawer from '@/components/common/FilterDrawer'
import _ from "lodash";

export default {
	components: {
		BHLoading,
		FilterDrawer,
	},
	data() {
		return {
			loading: false,
			currentPage: 1,
            pageSize: 10,
			selectedWorksheet: null,
			sortedInfo: {
                key: "updatedAt",
                order: "DESC",
            },
			filterQuery: '',
			statusList: {
				"available": "Available",
				"sold": "Sold",
				"hold": "Hold",
				"soldConditional": "Sold Conditional",
				"conditional": "Conditional",
				"inventory": "Inventory",
				"notreleased": "Not Released",
				"allocated": "Allocated",
				"approved_for_reservation": "Approved For Reservation",
				"reserved": "Reserved"
			},
			resizeObserver: null,
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},

		user() {
			return this.$store.state.user.user || {};
		},

		columns() {
			let list = [
				{
					title: "ID",
					key: "readableId",
					scopedSlots: { customRender: "readableId" },
					sorter: (a, b) =>
						a.readableId < b.readableId
							? -1
							: a.readableId > b.readableId
								? 1
								: 0,
					width: '85px'
				},
				{
					title: "Unit #1",
					key: "unit1",
					scopedSlots: { customRender: "unit1" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[0])
						const bb = this.getUnitNumber(b.data.units[0])
						return aa - bb
					}
				},
				{
					title: "Unit #2",
					key: "unit2",
					scopedSlots: { customRender: "unit2" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[1])
						const bb = this.getUnitNumber(b.data.units[1])
						return aa - bb
					}
				},
				{
					title: "Unit #3",
					key: "unit3",
					scopedSlots: { customRender: "unit3" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[2])
						const bb = this.getUnitNumber(b.data.units[2])
						return aa - bb
					}
				},
				{
					title: "Parking #",
					key: "parking",
					scopedSlots: { customRender: "parking" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.parking && a.data.addons.parking.length || 0) - (b.data && b.data.addons && b.data.addons.parking && b.data.addons.parking.length || 0);
					},
				},
				{
					title: "Locker #",
					key: "lockers",
					scopedSlots: { customRender: "lockers" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.lockers && a.data.addons.lockers.length || 0) - (b.data && b.data.addons && b.data.addons.lockers && b.data.addons.lockers.length || 0);
					},
				},
				{
					title: "Bike Rack #",
					key: "bikeRacks",
					scopedSlots: { customRender: "bikeRacks" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.bikeRacks && a.data.addons.bikeRacks.length || 0) - (b.data && b.data.addons && b.data.addons.bikeRacks && b.data.addons.bikeRacks.length || 0);
					},
				},
				{
					title: "Purchaser(s)",
					key: "purchasers",
					scopedSlots: { customRender: "purchasers" },
					sorter: (a, b) => {
						const aa = this.getPurchaserNames(a)
						const bb = this.getPurchaserNames(b)
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					}
				},
				{
					title: "Submitted By",
					key: "submittedBy",
					scopedSlots: { customRender: "submittedBy" },
					sorter: (a, b) => {
						const aa = this.getAssigneeName(a)
						const bb = this.getAssigneeName(b)
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					}
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: (a, b) =>
						a.status < b.status
							? -1
							: a.status > b.status
								? 1
								: 0,
				},
				{
					title: "Decline Reason",
					scopedSlots: { customRender: "declineReason" },
					key: "declineReason",
					sorter: (a, b) =>
						a.declineReason < b.declineReason
							? -1
							: a.declineReason > b.declineReason
								? 1
								: 0,
				},
				{
					title: "Date Created",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "Date Updated",
					key: "updatedAt",
					scopedSlots: { customRender: "updatedAt" },
					sorter: (a, b) =>
						moment(a.updatedAt).format("x") -
						moment(b.updatedAt).format("x"),
					defaultSortOrder: 'descend'
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
					width: '40px'
				}
			]

			if (this.instance.productType === 'lowrise') {
				list.splice(1, 6, ...[
					{
						title: "Lot #1",
						key: "lot1",
						scopedSlots: { customRender: "lot1" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[0], a.data.units[0])
							const bb = this.getLotName(b.data.lots[0], b.data.units[0])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
					{
						title: "Lot #2",
						key: "lot2",
						scopedSlots: { customRender: "lot2" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[1], a.data.units[1])
							const bb = this.getLotName(b.data.lots[1], b.data.units[1])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
					{
						title: "Lot #3",
						key: "lot3",
						scopedSlots: { customRender: "lot3" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[2], a.data.units[2])
							const bb = this.getLotName(b.data.lots[2], b.data.units[2])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
				])
			}

			return list
		},

		search() {
			return this.$store.state.globalSearch;
		},

		isAgent() {
			return this.$userType === 'agent'
		},

		lots() {
			return this.$store.state.appData.lots.filter(l => l.type === 'lot');
		},

		units() {
			return this.$store.state.appData.units;
		},

		unitNumberMapping() {
			let mapping = {}
			this.units.forEach(u => {
				mapping[u.id] = u.unitNumber
			})

			return mapping;
		},

		worksheets() {
			return this.$store.state.appData.worksheets
		},

		totalWorksheets() {
			return this.$store.state.appData.totalWorksheets || 0;
		},

		filter() {
			return this.$store.state.appData.filterDrawer.filter
		},
		addOns() {
			return this.$store.state.appData.addOns || { parking: [], lockers: [], bikeRacks: [] }
		},
		worksheetForms() {
			return this.$store.state.appData.worksheetForms || []
		},
	},
	watch: {
        search() {
            this.searchDebounce();
        },
		filter: {
			deep: true,
			handler(filter) {
				this.filterQuery = ''
				if (filter.status[0] !== "any") {
					filter.status.forEach(status => {
						this.filterQuery += `&status_in[]=${status}`
					})
				}
				if(filter.units.length !== 0) {
					filter.units.forEach(unit => {
						this.filterQuery += `&unit_in[]=${unit}`
					})
				}
				this.fetchWorksheetDetails()
			}
		}
    },
	created() {
		this.fetchWorksheetDetails();
	},
	methods: {
		req: (msg, required = true) => ({ required: required, message: msg }),

		searchDebounce: _.debounce(function () {
			this.fetchWorksheetDetails()
        }, 1000),

		fetchWorksheetDetails() {
			this.currentPage = 1
            this.getWorksheets();
            this.getTotalWorksheetCount();
		},

		async getTotalWorksheetCount() {
            try {
                let searchQuery = "?archived=true";
                if (this.search) {
                    searchQuery += `&_q=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `worksheets/:instance/count${searchQuery}${this.filterQuery}`
                );
				this.$store.commit('SET_WORKSHEET_COUNT', data)
            } catch (error) {
                console.error('Error while fetching worksheet count', error);
            }
        },
        async getWorksheets() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&_q=${this.search}`;
                }
                const { data } = await this.$api.get(
                    `/worksheets/:instance?archived=true${searchQuery}${this.filterQuery}&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`
                );
                this.$store.commit('SET_WORKSHEETS', data)
            } catch (error) {
                console.error('Error occurred while fetching worksheet list', error);
            }
            this.loading = false;
        },

		deleteWorksheet(worksheet) {
			let self = this;
			this.$confirm({
				title: "Delete Worksheet",
				content: (h) => <div>Are you sure you want to delete worksheet <strong>{worksheet.readableId}</strong>?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: async () => {
					try {
						await self.$api.delete(`/worksheets/${self.instance.id}/${worksheet.id}`);
						self.fetchWorksheetDetails();
						self.$store.dispatch('FETCH_UNITS')
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while deleting worksheet. Please Try Again!"))
						}
					}
				},
			});
		},

		editWorksheet(worksheet) {
			const worksheetForm = worksheet.form || (this.worksheetForms && this.worksheetForms.length && this.worksheetForms[0]) || null
			this.$store.commit('SET_WORKSHEET_FORM', worksheetForm)
			this.$router.push(`/edit/${worksheet.id}`)
		},

		getPurchaserNames(worksheet) {
			return worksheet.data.purchasers && worksheet.data.purchasers.filter(p => p.email).map(p => p.firstName + " " + p.lastName).join(',\n')
		},

		getAssigneeName(worksheet) {
			return worksheet.submittedBy && worksheet.submittedBy.firstName + " " + worksheet.submittedBy.lastName;
		},

		getDate(date) {
			return moment(date).format("DD/M/YYYY");
		},

		showFilter() {
			this.$store.commit('OPEN_FILTER')
		},

		getLotName(lotId, unitId) {
			const lot = this.lots.find(l => l.id === lotId);

			if (lot) {
				const unit = lot.units.find(u => u.id === unitId);

				if (unit) {
					return `Lot ${lot && lot.name} (${unit.unitGroup && unit.unitGroup.name} - ${unit.name})`;
				} else {
					return `Lot ${lot && lot.name}`
				}
			}
			return ''
		},

		getUnitNumber(unitId) {
			const unit = this.units.find(u => u.id === unitId);

			return unit ? Number(unit.unitNumber) : ''
		},

		getAddOnNames(ids, type = 'parking') {
			let names = '';
			ids.forEach(id => {
				const addon = this.addOns[type].find(a => a.id === id)
				if (addon) {
					names += names ? ", " + addon.name : addon.name;
				}
			})

			return names
		},

		classLookup(status) {
			let lookup = {
				pending: 'bg-med-gray text-white',
				approved: 'bg-teal text-white',
				reserved: 'bg-dark-green text-white',
				declined: 'bg-red text-white',
				void: 'bg-red text-white',
				draft: 'bg-gray text-white',
				sold: 'bg-pink text-white',
			}
			let textLookup = {
				void: 'Voided'
			}
			return {
				color: lookup[status] || 'badge',
				text: textLookup[status] || status,
			}
		},

		updateScrollVisibility() {
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if (scrollContent && scrollLeftButton && scrollRightButton) {
				if (scrollContent.offsetWidth < (+scrollContent.scrollWidth - 15)) {
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},

		handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getWorksheets();
        },
	},

	mounted() {
		this.$nextTick(() => {
			const scrollableArea = document.querySelector('div.ant-table-body')

			var scrollLeftButton = document.createElement('div');
			var scrollRightButton = document.createElement('div');

			scrollLeftButton.className = 'scroll-button scroll-left';
			scrollLeftButton.innerHTML = '&lt;';
			scrollableArea.appendChild(scrollLeftButton);

			scrollRightButton.className = 'scroll-button scroll-right';
			scrollRightButton.innerHTML = '&gt;';
			scrollableArea.appendChild(scrollRightButton);


			scrollLeftButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			});

			scrollRightButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				})
			});

			this.updateScrollVisibility();
		})

		let scrollableArea = document.querySelector('div.ant-table-body');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		this.resizeObserver.observe(scrollableArea);

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},

};
</script>

<style lang="scss" scoped>
.template-list i {
	font-size: 25px;
	padding: 6px;
	background: var(--light-purple);
	border-radius: 50%;
	width: 15px;
	height: 15px;
}

.popoverContent {
	height: max-content;
	line-height: 30px;
	padding: 0 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	color: var(--primary);
	cursor: pointer;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
	padding: 5px;
	background-color: white;
	border-radius: 20px;
}
</style>
